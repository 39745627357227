import { Divider, message } from 'antd';
import Skeleton from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingOutlined from '@ant-design/icons/es/icons/LoadingOutlined';
import Upload from 'antd/es/upload';
import Select from 'antd/es/select';
import AgoraRTC from 'agora-rtc-react';
import ReactDOM from "react-dom";
import { useRouter } from '../../routerHook';

export const rtc = {
    // For the local client
    client: null,
    // For the local audio and video tracks
    localAudioTrack: null,
    localVideoTrack: null,
	proxy: "https://sua-ap-web-1.agora.io"
  };
 
export const AppointmentPage = ({ token }) => {

	const [load, setLoading] = useState(false);
	const [upComingVisit, setUpComingVisit] = useState(0);
	const { t } = useTranslation('home');
	const [cancelledOrdersNum, setCancelledOrdersNum] = useState(0);
	const [totalRev, setTotalRev] = useState(0);
	const [totalRevToday, setTotalRevToday] = useState(0);
	const [appointmentsLoad, setAppointmentsLoading] = useState(false);

	const [userData, setData] = useState();
	const [profilePic, setProfilePic] = useState(null);
	const [appointment, setAppointmentData] = useState();
	const [appointment2, setAppointmentData2] = useState();
	const [orderTotal, setOrderTotal] = useState();
	const [doctorId2, setDoctorId2] = useState(null);
	const [clinicServiceId2, setClinicServiceId2] = useState(null);
	const [fees, setFees] = useState(0);


	
	const [messageApi, contextHolder] = message.useMessage();
	const tableRef = useRef(null);
	const [uploadId, setUploadId] = useState(null);
	const [prescriptionText, setPrescriptionText] = useState('');
	const [timeslot, setTimeSlot] = useState(null);
	const [timeslot2, setTimeSlot2] = useState(null);

	const [loading, setLoadings] = useState(false);
	const [patientId, setpatientId] = useState(null);
	const [code, setCode] = useState(null);
	const [redeemedPoints, setRedeemedPoints] = useState(null);
	const [doctorId, setDoctorId] = useState(null);
	const [doctorName, setDoctorsName] = useState('');
	const [clinicServiceId, setClinicServiceId] = useState(null);
	const [joined, setJoined] = useState(false);
	const [video, setVideo] = useState(false);
	const [rtcV, setRtc] = useState(false);

	const [doctors, setDoctors] = useState([]);
	const [doctorData, setDoctorData] = useState("");
	const { Option } = Select;
	const [notificationBody, setNotificationBody] = useState();
	const router = useRouter();

	const sendNotification = async () => {

		await fetch(CONSTANTS.DEFAULT_URL + 'polyclinic/send_notification/'+patientId,{
			method: 'POST',
			body: JSON.stringify({
				body:notificationBody			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			},
		}).then(async (response) => {
			response = await response.json();


			if (response.success = 1) {
				message.success("تم الارسال");
				setNotificationBody('');
				return false;

			}else{
				message.error("بم يتم الارسال");

			}

		}).catch((error) => {
			message.error(error.message);

		});



	}
	const muteAudio = async () => {
		rtcV.localAudioTrack.stop();
	}

	const muteVideo = async () => {
		rtcV.localVideoTrack.stop();
	}

	const unmuteAudio = async () => {
		rtcV.localAudioTrack.play();
	}

	const unmuteVideo = async () => {
		rtcV.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

		rtc.localVideoTrack.play("local-stream");

	}

	async function handleSubmit(chanell ,token , user_id ) {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ 
			  audio: true, 
			  video: true 
			});
			// User has granted access to camera and microphone
			console.log('Camera and microphone permissions granted');
			// You can use the stream object to display the video/audio or handle it accordingly
		  } catch (error) {
			// User denied access to camera and/or microphone
			message.error('يجب تفعيل الكاميرا والمايكروفون');
			// You can handle the denial here, such as displaying a message to the user
			return false;
		  }

		
		  
		try {
		  setJoined(true);
	
		  rtc.client = AgoraRTC.createClient();
		  rtc.client.startProxyServer(3);
	  await rtc.client.join(
		"c0d00230fa7c46928e28346015525ff8",
			chanell,
			token,
			user_id
		  );
	
		  // Create an audio track from the audio captured by a microphone
		  rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
		  // Create a video track from the video captured by a camera
		  try {
			rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
			setVideo(true);
		  }catch(err){
			console.log("no cam",err);
			setVideo(false);

		  }
	
		  rtc.localVideoTrack.play("local-stream");
	
		  rtc.client.on("is-using-cloud-proxy", async (user, mediaType) => { 
			// Subscribe to a remote user
			await rtc.client.subscribe(user);
			console.log("subscribe success");
			// console.log(user);
	
			if (mediaType === "video" || mediaType === "all") {
			  // Get `RemoteVideoTrack` in the `user` object.
			  const remoteVideoTrack = user.videoTrack;
	
			  // Dynamically create a container in the form of a DIV element for playing the remote video track.
			  const PlayerContainer = React.createElement("div", {
				id: user.uid,
				className: "stream",
			  });
			  ReactDOM.render(
				PlayerContainer,
				document.getElementById("remote-stream")
			  );
	
			  user.videoTrack.play(`${user.uid}`);
			  try {
				user.videoTrack.play(`${user.uid}`);

			  } catch (err) {
				console.error('Error accessing camera:', err);
			  }
		
			}
	
			if (mediaType === "audio" || mediaType === "all") {
			  // Get `RemoteAudioTrack` in the `user` object.
			  const remoteAudioTrack = user.audioTrack;
			  // Play the audio track. Do not need to pass any DOM element
			  try {
				remoteAudioTrack.play(`${user.uid}`);

			  } catch (err) {
				console.error('Error accessing camera:', err);
			  }
			}
		  });
	
		  rtc.client.on("user-unpublished", (user) => {
			// Get the dynamically created DIV container
			const playerContainer = document.getElementById(user.uid);
			console.log(playerContainer);
			// Destroy the container
			playerContainer.remove();
		  });
	
		  // Publish the local audio and video tracks to the channel
		  await rtc.client.publish([rtc.localAudioTrack , rtc.localVideoTrack]);
		  setRtc(rtc);
		  console.log("publish success!");
		} catch (error) {
			console.error("error",error);
			message.error("لا يمكنك الاتصال الان");
			setTimeout(()=>{
				window.location.reload();
			},2000)
		}
	  }
	
	  async function handleLeave() {
		try {
		  const localContainer = document.getElementById("local-stream");
	
		  rtc.localAudioTrack.stop();
		  rtc.localVideoTrack.stop();
		  rtc.localAudioTrack.close();
		  rtc.localVideoTrack.close();

		  setJoined(false);
		  localContainer.textContent = "تم انهاء المكالمه";
	
		  // Traverse all remote users
		  rtc.client.remoteUsers.forEach((user) => {
			// Destroy the dynamically created DIV container
			const playerContainer = document.getElementById(user.uid);
			playerContainer && playerContainer.remove();
		  });
		  await rtc.client.leave();
		  await rtc.client.leave();

		  // Leave the channel

		} catch (err) {
		  console.error(err);
		  window.location.reload();

		}
	  }
		  const remoteRef = useRef("");
		  const leaveRef = useRef("");


	const makeCall = async () => {
		

		const check_auth_response = await check_auth(token);
	
	

	

		await fetch(CONSTANTS.DEFAULT_URL + 'polyclinic/makeCall/'+patientId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			},
		}).then(async (response) => {
			response = await response.json();


			console.log(response);

			if(response.polyclinicToken){

				handleSubmit(response.sessionId2,response.polyclinicToken ,response.user_id);
            
			}
			if (response.errorMessage) {
				message.error(response.errorMessage);
				return false;

			}

		}).catch((error) => {
			message.error(error.message);

		});



	}
	const saveOrder = async (type) => {
		

		const check_auth_response = await check_auth(token);
		let items = [];
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}

		let data = {


			'promoCode': code,
			'redeemedPoints': redeemedPoints,
			'location': null,
			'paymentType': 'pre_paid',
			'paymentStatus':'draft',
			'web':true,
			'orderFrom':"doctor",
			'tawhood':true,

			'patient': patientId,
			'type': "appointment",
			'items': [ { itemId: clinicServiceId2 } ],
			'doctor': doctorId,
			'timeslot': timeslot2,
			'complaint': '',
			'totalPrice': orderTotal,
			'prescription':null,
			

			'showMedicalHistory': true

		};

	

	

		await fetch(CONSTANTS.DEFAULT_URL + 'orders', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			},



			///api/patients?locale=ar&filters%5Bprofile%5D%5Buser%5D%5BmobileNumber%5D%5B%24contains%5D=%2B201062328373&populate=profile
			body: JSON.stringify({
				"data": data
			})
		}).then(async (response) => {
			response = await response.json();
			if (response.errorMessage) {
				message.error(response.errorMessage);
				return false;

			}

			if (response.id) {
				message.success("تم الطلب بنجاح");
				router.push("/ar/appointments");

				setDoctorId2(null);

			}
		}).catch((error) => {
			message.error(error.message);

		});



	}
	const AddPharmacyOrder = async (type) => {
		if (uploadId === null || uploadId === undefined || uploadId === '') {
			message.error("ارفع الروشته اولا");
			return false;
		}


		const check_auth_response = await check_auth(token);
		let items = [];
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}

		let data = {
			'newUser': false,
			'promoCode': code,
			'redeemedPoints': redeemedPoints ? redeemedPoints : 0,
			'paymentType': 'post_paid',
			'patient': patientId,
			'doctor': doctorId,
			'orderFrom': 'doctor',
			'type': type,
			'items': [],
			'location': 'lab',
			'note': '',
			'totalPrice': null,
			'prescription': [uploadId]


		};

		// |     promoCode: '',
		// 0|doxx  |     redeemedPoints: null,
		// 0|doxx  |     location: 'lab',
		// 0|doxx  |     paymentType: 'post_paid',
		// 0|doxx  |     patient: 987,
		// 0|doxx  |     type: 'lab',
		// 0|doxx  |     address: 7301,
		// 0|doxx  |     items: [],
		// 0|doxx  |     prescription: [ 5613 ],
		// 0|doxx  |     orderFrom: 'patient',
		// 0|doxx  |     note: '',
		// 0|doxx  |     totalPrice: null,
		// 0|doxx  |     supplier: 5

		if (type === "lab") {
			data.supplier = lab;
			if (lab === 0 || lab === undefined || lab === null || lab === '') {
				message.error("اختر موزع اولا");
				return false;
			}
		}

		if (type === "scan") {
			data.supplier = scan;
			if (scan === 0 || scan === undefined || scan === null || scan === '') {
				message.error("اختر موزع اولا");
				return false;
			}
		}
		if (type === "pharmacy") {
			data.supplier = null;

		}

		await fetch(CONSTANTS.DEFAULT_URL + 'orders', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			},



			///api/patients?locale=ar&filters%5Bprofile%5D%5Buser%5D%5BmobileNumber%5D%5B%24contains%5D=%2B201062328373&populate=profile
			body: JSON.stringify({
				"data": data
			})
		}).then(async (response) => {
			response = await response.json();
			if (response.errorMessage) {
				message.error(response.errorMessage);
				return false;

			}

			if (response.id) {
				message.success("تم الطلب بنجاح");

			}
		}).catch((error) => {
			message.error(error.message);

		});



	}

	const onTimeSlotChange = (e, timeslot) => {

		e.stopPropagation();
		e.preventDefault();
		if (document.getElementsByClassName("timing selected").length > 0) {
			document
				.getElementsByClassName("timing selected")[0]
				.classList.remove("selected");

		}
		e.target.classList.add("selected");
		setTimeSlot(timeslot.id);



	};


	const onTimeSlotChange2 = (e, timeslot) => {

		e.stopPropagation();
		e.preventDefault();
		if (document.getElementsByClassName("timing2 selected").length > 0) {
			document
				.getElementsByClassName("timing2 selected")[0]
				.classList.remove("selected");

		}
		e.target.classList.add("selected");
		setTimeSlot2(timeslot.id);



	};
	const params = useParams();

	const getAppointment2 = async (id) => {
		try {

			let response = await fetch(CONSTANTS.DEFAULT_URL + 'polyclinc/clinicslots/' + id, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token

				},

			})
			let json = await response.json();
			console.log(json);
			if (response.status !== 200) {
				//   window.location = "/"+i18n.language+""
			}

			// return { success: true, data: json };
			console.log('ddddddd',json.data);
			setAppointmentData2(json.data);



		} catch (error) {
			return { success: false };
		}

	}
	const getAppointment = async () => {
		try {

			let response = await fetch(CONSTANTS.DEFAULT_URL + 'polyclinic/appointment/' + params.id, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token

				},

			})
			let json = await response.json();
			console.log(json);
			if (response.status !== 200) {
				//   window.location = "/"+i18n.language+""
			}

			return { success: true, data: json };



		} catch (error) {
			return { success: false };
		}

	}
	const updateTimeSlot = async () => {
		if (timeslot == null || timeslot == undefined) {
			message.error('اختر موعد اولا');
			return false;
		}
		try {

			let response = await fetch(CONSTANTS.DEFAULT_URL + 'polyclinic/updateTimeSlot/' + params.id, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token

				},
				body: JSON.stringify({
					"data": {
						"timeslot": timeslot,
					}
				})
			})
			let json = await response.json();
			console.log(json);
			if (response.status == 200) {
				await update_state(params.id, 'rescheduled');

				//   window.location = "/"+i18n.language+""
				message.success("تم تغير الموعد");
				setTimeout(() => {
					window.location.reload();

				}, 1000);

			}

			return { success: true, data: json };



		} catch (error) {
			return { success: false };
		}

	}

	const [fileList, setFileList] = useState([]);

	const [scans, setScans] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [labs, setLabs] = useState([]);
	const [lab, setLab] = useState(null);
	const [scan, setScan] = useState(null);

	const [error, setError] = useState(null);
	// const getClinicData = async (id) => {
	// 	try {

	// 		let response = await fetch(CONSTANTS.DEFAULT_URL + 'polyclinic/clinic/' + id, {
	// 			method: 'GET',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				'Authorization': 'Bearer ' + token

	// 			},
	// 		})
	// 		let json = await response.json();
	// 		console.log(json);
	// 		if (response.status !== 200) {
	// 			//   window.location = "/"+i18n.language+""
	// 		}

	// 		return { success: true, data: json };



	// 	} catch (error) {
	// 		return { success: false };
	// 	}

	// }
	useEffect(() => {
		 
		async function ch(){
			await check_auth(token);
		};
		ch();
		axios(CONSTANTS.DEFAULT_URL + "suppliers?filters[$and][0][type][$eq]=lab")
			.then((labResponse) => {
				setDataLoaded(true);

				setLabs(labResponse.data.data);
				setLab(labResponse.data.data[0].id);

			})
			.catch(setError);

		axios(CONSTANTS.DEFAULT_URL + "suppliers?filters[$and][0][type][$eq]=scan")
			.then((labResponse) => {
				setDataLoaded(true);

				setScans(labResponse.data.data);
				setScan(labResponse.data.data[0].id);

			})
			.catch(setError);



		getAppointment().then((e) => {

			setAppointmentData(e.data.order);
			setpatientId(e.data.order.patient.id);
			setDoctorId(e.data.order.doctor?.id);
			setAppointmentsLoading(true);

			setLoading(true)
		})


		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}



		axios.get(CONSTANTS.DEFAULT_URL + 'polyclinc/telehealthservice', {
			headers: headers
		})
			.then(function (response) {

				setDoctors(response.data.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);

			});

	}, [])
	const get_patient_image = (data) => {
		if (data.patient.data !== null) {
			return data.patient.profile.maritalStatus;

		}
	}

	const get_status = (data) => {

		if (data.appointment.status !== null) {
			return data.appointment.status;

		}
	}
	const get_timeSlot = (data) => {

		if (data.appointment.timeslot !== null) {
			return data.appointment.timeslot.start;

		}
	}
	const get_fees = (data) => {

		if (data.totalPrice !== null) {
			return data.totalPrice;

		}
	}
	const get_payment = (data) => {

		if (data.paymentStatus !== null) {
			return data.paymentStatus;

		}
	}
	const get_booking_date = (data) => {

		if (data.date !== null) {
			var date = new Date(data.date);

			return date.toLocaleDateString('ar-EG-u-nu-latn', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });

		}
	}
	const get_order_data = (data) => {
		var d = [];

		if (data.appointment.doctor_service !== null) {
			var type = data.appointment.doctor_service.serviceType;
			var consultationDuration = data.appointment.doctor_service.consultationDuration
			if (type === 'telehealth') {
				d.push(t('TeleHealth'));
				d.push(" (" + consultationDuration + " " + t("Minute") + ")");

			} else if (type === 'clinic') {
				d.push(t('Clinic'));

			} else if (type === 'homevisit') {
				d.push(t('Home Visit'));

			}
		}
		return d;
	}
	const get_patient_name = (data) => {
		if (data.patient !== null) {
			if (i18n.language === 'ar' && data.patient.profile.firstname_ar !== null && data.patient.profile.firstname_ar !== '') {
				return (data.patient.profile.firstname_ar + ' ' + data.patient.profile.lastname_ar);

			}
			return (data.patient.profile.firstname + ' ' + data.patient.profile.lastname);

		}
	}

	const [progress, setProgress] = useState(0);
	


	const get_patient_whatsnumber = (data) => {
		if (data.patient !== null) {
			return (data.patient.profile.whatsappNumber);

		}
	}

	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoadings(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (url) => {
				setLoadings(false);
			});
		}
	};

	const convertToEgyTime = (datatime) => {
		const timeSlotDatetime = new Date(datatime).getTime();
		const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
		newTimeSlot.setHours(newTimeSlot.getHours() - 2);

		return newTimeSlot.toLocaleTimeString();
	};
	const ConvertToArabicNumbers = (num) => {
		if (i18n.language === 'ar') {
			const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
			return new String(num).replace(/[0123456789]/g, (d) => { return arabicNumbers[d] }).replace("PM", "مساءا").replace("AM", "صباحا");

		}
		return num;
	}
	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <div class="upload-img">
				<div class="change-photo-btn">
					<span><i class="fa fa-upload"></i>{t('Upload Prescription')}</span>
					<input type="file" class="upload" />
				</div>
			</div>}

		</div>
	);

	const update_state = async (order_id, status) => {
		setLoading(true);

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}
		await axios.put(CONSTANTS.DEFAULT_URL + 'polyclinic/updateAppointMentStatus/' + order_id, {
			"data": {
				"status": status
			}
		}, { headers: headers })
			.then(function (response) {
				message.success('تم التحديث');
				setTimeout(() => {
					window.location.reload();

				}, 1000);

				// setLoading(false);
			}).catch((error) => {
				message.error(error.response.data.error.message);
				// setLoading(false);


			});
	}
	return (
		<>{load ? (<>
		 {joined ? (<>	
			<div class="col-md-7 col-lg-8 col-xl-9">

<div class="row">
	<div class="col-lg-12">
		<div class="card dash-cards">	
					<div class="card-body">
          <div className="container">
           
            <input
			className='btn btn-danger'
              type="button"
              ref={leaveRef}
              value="انهاء المكالمة"
              onClick={handleLeave}
              disabled={joined ? false : true}
            />

<input
			className='btn btn-danger'
              type="button"
              ref={leaveRef}
              value="كتم الصوت"
              onClick={muteAudio}
              disabled={joined ? false : true}
            />
			<input
			className='btn btn-success'
              type="button"
              ref={leaveRef}
              value="تفعيل  الصوت"
              onClick={unmuteAudio}
              disabled={joined ? false : true}
            />

<input
			className='btn btn-danger'
              type="button"
              ref={leaveRef}
              value="كتم ألفديو"
              onClick={muteVideo}
              disabled={joined ? false : true}
            />


		  <input
			className='btn btn-success'
              type="button"
              ref={leaveRef}
              value="تفعيل ألفديو"
              onClick={unmuteVideo}
              disabled={joined ? false : true}
            />
          </div>
          {joined ? (
            <div className='row'>
			<div id="local-stream"  className="stream local-stream">{video ? (<></>):(<>لا يوجد فديو</>)}</div>
           
              <div
                id="remote-stream"
                ref={remoteRef}
                className="stream remote-stream"
              ></div>
            </div>
          ) : null} 
        
        </div></div></div></div></div></>): 
		
		(<>	<div class="col-md-7 col-lg-8 col-xl-9">

<div class="row">
	<div class="col-lg-12">
		<div class="card dash-cards">

			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-center mb-0" ref={tableRef}>
						<thead>
							<tr>
								<th>{t('Patient')}</th>
								<th>{t('Order No.')}</th>

								<th>{t('Booking Date')}</th>
								<th>{t('Total')}</th>
								<th>{t('Order Data')}</th>
								<th>{t('Time')}</th>

								<th>{t('Payment Status')}</th>

								<th>{t('Status')}</th>
								<th>{t('Start Call')}</th>
<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{appointmentsLoad && appointment != null ? (

								<tr>
									<td>

										<h2 class="table-avatar">
											{/* <a href="#" class="avatar avatar-sm me-2">
<img class="avatar-img rounded-circle" src={get_patient_image(appointment)} alt={get_patient_name(appointment)} />
</a> */}
											<a href="#">{get_patient_name(appointment)} </a>
										</h2>
									</td>
									<td>#{appointment.id}</td>

									<td>{ConvertToArabicNumbers(get_booking_date(appointment))}</td>
									<td>{ConvertToArabicNumbers(get_fees(appointment))} {t('pound')}</td>
									<td>{get_order_data(appointment)}</td>
									<td>{ConvertToArabicNumbers(convertToEgyTime(get_timeSlot(appointment)))}</td>

									{/* <td></td> */}
									<td>{t(get_payment(appointment))}</td>

									<td>{t(get_status(appointment))}</td>
									<td><Link className='btn btn-success' onClick={()=>{makeCall()}}>{t('Call')}</Link></td>

									<td class="text-end">
										<div class="table-action">

											{get_status(appointment) !== 'completed' && get_status(appointment) !== 'cancelled' ? (<a href="#" onClick={() => { update_state(params.id, 'cancelled') }} class="btn btn-sm bg-danger-light">
												{t('Cancel')}
											</a>) : (<></>)}
										</div>
									</td>
									<td>{get_status(appointment) === 'documents_submitted' ? (<>

<div class="submit-section proceed-btn text-end">
	<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={() => { update_state(params.id, 'completed') }}  >{'اكتمال الاستشاره'} </a>

</div></>) : (<></>)}</td>
								</tr>
							) : (
								<>
									<Skeleton avatar
										paragraph={{
											rows: 4,
										}} active />
								</>
							)}


						</tbody>
					</table>
					<Divider />

					<div class="col-12 col-md-6 ">
						<div className='row'>
						<div class="form-group col-md-9">
											<input type="text" placeholder='ارسل اشعار' class="form-control" onChange={(e)=>{setNotificationBody(e.target.value)}} value={notificationBody} />
										</div>

										<div class="submit-section col-md-3">
									<button type="button" onClick={()=>{sendNotification()}} class="btn btn-primary submit-btn">ارسل الان</button>
								</div>	
									</div>

									</div>

								

					<Divider />

					{get_status(appointment) === 'scheduled' ? (<>

						<nav class="user-tabs mb-4">

							<ul class="nav nav-tabs nav-tabs-bottom nav-justified">
								{appointment.doctor_service.working_days.length > 0 ?
									(appointment.doctor_service.working_days.map((day, key) => {
										return (
											<li class="nav-item day" >
												<a className={`nav-link ${key === 0 ? "active" : ""}`} href={"#doc_" + day.id} data-bs-toggle="tab">{t(day.day)} <br /><>{day.timeslots[0]
													? day.timeslots[0].start.split(
														"T"
													)[0]
													: null}</> </a>
											</li>

										)
									})
									) : (<>{t('Loading')}</>)
								}

							</ul>
						</nav>

						<div class="tab-content pt-0">
							{appointment.doctor_service.working_days.length > 0 ?
								(appointment.doctor_service.working_days.map((day, key) => {
									return (
										<div role="tabpanel" id={"doc_" + day.id} className={`tab-pane ${key === 0 ? " show active" : ""}`}>
											<div class="row">

												<div class="schedule-cont">
													<div class="row">
														<div class="col-md-12">

															<div class="time-slot">
																<ul class="clearfix">



																	{day.timeslots.length > 0 ?
																		(day.timeslots.map((timeslot) => {
																			return (
																				<>

																					{timeslot.status === "available" ? (<li className='slot_m'>

																						<a className="timing" onClick={(e) => {
																							onTimeSlotChange(e, timeslot);
																						}}>
																							<span>{ConvertToArabicNumbers(convertToEgyTime(timeslot.start))} </span>
																						</a>
																					</li>) : (<li className="text-danger" >

																						<a class="timing"  >
																							<span className='text-danger'>{t(timeslot.status)}</span>
																						</a>
																					</li>)}

																				</>)
																		})
																		) : (<>{t('Not Avaliable')}</>)
																	}





																</ul>
															</div>


														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})
								) : (<>Loading Days</>)
							}

						</div>
						<div class="submit-section proceed-btn text-end">
								<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={() => { updateTimeSlot() }} >{'تغيير الموعد'} </a>

							</div>
					


					</>) : (<></>)}

					<Divider />
					{get_status(appointment) === 'pending' ? (<>
							

								<div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={() => { update_state(params.id, 'inProgress') }}  >{'بدا الاستشاره'} </a>

								</div>

							
						</>) : (<></>)}


						{get_status(appointment) !== 'cancelled'  && get_status(appointment) !== 'completed' && get_status(appointment) !== 'waiting_for_documents' && get_status(appointment) !== 'documents_submitted' ? (<>
							

							<div class="submit-section proceed-btn text-end">
								<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={() => { update_state(params.id, 'waiting_for_documents') }}  >{'جاهز لرفع الملفات من قبل العميل'} </a>

							</div>

						
					</>) : (<></>)}

					{ get_status(appointment) === 'waiting_for_documents'  ? (<>
							

							<div class="submit-section proceed-btn text-end">
								<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={() => { update_state(params.id, 'documents_submitted') }}  >{'تم رفع الملفات من قبل العميل'} </a>

							</div>

						
					</>) : (<></>)}
					<table class="table table-hover table-center mb-0" >
						<thead>
							<tr>
								<th>{t('File')}</th>
								<th>{t('Type')}</th>

								<th>{t('Url')}</th>

								<th></th>
							</tr>
						</thead>
						<tbody>

							{appointment.appointment.consultation?.previousReports ? (<>
								{appointment.appointment.consultation.previousReports.map((c, i) => {
									return (<tr>
										<td>{c.caption ?? c.name}</td>
										<td>{c.formats?.thumbnail?.mime}</td>
										<td><a href={c.url}>{t('Url')}</a></td>
										<td></td>
									</tr>


									)

								})}</>) : (<></>)}






						</tbody>
					</table>
					<Divider />




					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>العيادة </label>
							(<span className='text-danger'>{t('Fees')} : {fees}</span>)
							<Select
								size='large'

								showSearch
								placeholder={'اختر العيادة'}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
									option.props.label.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
								}
								value={doctorName}
								onChange={(e, k) => { setDoctorsName(k.key); setDoctorData(k.value); 
									getAppointment2(e);setTimeSlot2(null);setDoctorId2(k.doctorIDD);setClinicServiceId2(k.clinicSID);setFees(k.fee); }}
								className="col-md-12 "
							>
								{doctors.map((c, i) => {
									return (
										<Option value={c.id} key={c.doctor.profile.firstname+' '+c.doctor.profile.lastname} clinicSID={c.id} doctorIDD={c.doctor.id} fee={c.fee}  label={c.doctor.profile.firstname+' '+c.doctor.profile.lastname}>
											<>(<span className='text-danger'>Fees : {c.fee}</span>) {c.doctor.profile.firstname+' '+c.doctor.profile.lastname}  </>
										</Option>
									);
								})}                  </Select></div>



					</div>





					<nav class="user-tabs mb-4">

<ul class="nav nav-tabs nav-tabs-bottom nav-justified">
{appointment2 ? (<>{appointment2.working_days.length > 0 ?
(appointment2.working_days.map((day, key) => {
return (
<li class="nav-item day" >
	<a className={`nav-link ${key === 0 ? "active" : ""}`} href={"#doc2_" + day.id} data-bs-toggle="tab">{t(day.day)} <br /><>{day.timeslots[0]
		? day.timeslots[0].start.split(
			"T"
		)[0]
		: null}</> </a>
</li>

)
})
) : (<>لا يوجد مواعيد</>)
}
</>):(<></>)}

</ul>
</nav>

{appointment2 ? (<><div class="tab-content pt-0">


{appointment2.working_days.length > 0 ?
(appointment2.working_days.map((day, key) => {
return (
<div role="tabpanel" id={"doc2_" + day.id} className={`tab-pane ${key === 0 ? " show active" : ""}`}>
<div class="row">

	<div class="schedule-cont">
		<div class="row">
			<div class="col-md-12">

				<div class="time-slot">
					<ul class="clearfix">



						{day.timeslots.length > 0 ?
							(day.timeslots.map((timeslot) => {
								return (
									<>

										{timeslot.status === "available" ? (<li className='slot_m'>

											<a className="timing timing2" onClick={(e) => {
												onTimeSlotChange2(e, timeslot);
											}}>
												<span>{ConvertToArabicNumbers(convertToEgyTime(timeslot.start))} </span>
											</a>
										</li>) : (<li className="text-danger" >

											<a class="timing timing2"  >
												<span className='text-danger'>{t(timeslot.status)}</span>
											</a>
										</li>)}

									</>)
							})
							) : (<>{t('Not Avaliable')}</>)
						}





					</ul>
				</div>


			</div>
		</div>
	</div>
</div>
</div>
)
})
) : (<>لا يوجد مواعيد</>)
}





</div></>) :(<></>)}

{timeslot2 !== null ? (<>


<div class="col-12 col-md-6">
										<div class="form-group">
											<label>{t('Order Total Price')}</label>
											<input type="text" class="form-control" onChange={(e)=>{setOrderTotal(e.target.value)}} value={orderTotal} />
										</div>
									</div>

									
									<div class="submit-section">
									<button type="button" onClick={()=>{saveOrder()}} class="btn btn-primary submit-btn">{t('Save Changes')}</button>
								</div>
</>) : (<></>)}







				</div>
			</div>
		</div>
	</div>

</div>

</div></>)}
		
		</>) : (<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}
		</>
	)
}
