import { message, Space } from 'antd';
import Skeleton  from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import axios, { Axios } from 'axios';

export const PolyclinicClinics = ({token }) => {
	const [load,setLoading] = useState(false);
	const [upComingVisit,setUpComingVisit] = useState(0);
	const {t} = useTranslation('home');
		const [cancelledOrdersNum,setCancelledOrdersNum] = useState(0);
	const [totalRev,setTotalRev] = useState(0);
	const [totalRevToday,setTotalRevToday] = useState(0);
    const [clinicsLoad , setclinicsLoading] = useState(false);


    const [userData , setData] = useState();
	const [profilePic, setProfilePic] = useState(null);
    const [clinics , setClinicsData] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();
    const tableRef = useRef(null);


	const getAppointments = async ()=>{
		try {
	
			let response = await  fetch(CONSTANTS.DEFAULT_URL+'polyclinc/telehealthservice', {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json' ,
				'Authorization': 'Bearer '+token
	
			  },
			})
			let json = await response.json();
			console.log(json);
			if(response.status !== 200){
				//   window.location = "/"+i18n.language+""
			}
		
				return { success: true, data: json };
		
			
		
		} catch (error) {
			return { success: false };
		  }
	
	 }	


 useEffect(() => {

		getAppointments().then((ap)=>{
			setClinicsData(ap.data.data);
			setclinicsLoading(true);
	
			setLoading(true)
	
	

	});
 }, [])

const convertToEgyTime = (datatime) => {
	const timeSlotDatetime = new Date(datatime).getTime();
	const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
	newTimeSlot.setHours(newTimeSlot.getHours() - 2);

	return newTimeSlot.toLocaleTimeString();
  };
  const ConvertToArabicNumbers = (num) => {
	if(i18n.language === 'ar'){
		const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
		return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]}).replace("PM","مساءا").replace("AM","صباحا");
	   
	}
	return num;
}

const deleteClinic =async (id)=>{
	try {
	
		let response = await  fetch(CONSTANTS.DEFAULT_URL+'polyclinic/deleteTelehealth/'+id, {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json' ,
			'Authorization': 'Bearer '+token

		  },
		})
		let json = await response.json();
		console.log(json);
		if(response.status == 200){
			message.success('تم الحذف');

			//   window.location = "/"+i18n.language+""
			setTimeout(()=>{

				window.location = "/ar/clinics";

},1000)
		}
	
	
		
	
	} catch (error) {
      message.error(t(`Can not delete`));
	}
}
  return (
<>{load ? (<>
	<div class="col-md-7 col-lg-8 col-xl-9">
	
		<div class="row">
			<div class="col-lg-12">
				<div class="card dash-cards">
					<div class="card-header">
						<div class="today-appointment-title">
							<h5 class="mb-0">{t('Clinics')}</h5>
						</div>
					</div>
					<div class="card-body">
						<div class="table-responsive">
						<table class="table table-hover table-center mb-0" ref={tableRef}> 
												<thead>
													<tr>
													<th>{t('#')}</th>

														<th>{t('Clinic')}</th>

														<th></th>
													</tr>
												</thead>
												<tbody>
				{clinicsLoad ? (
clinics.length > 0 ? (

	clinics.map((clinic, i) => {
	 
	  return (
		clinic != null ? <>	
		
					
													<tr>
														<td>{clinic.id}</td>
														<td>{clinic.doctor.profile?.firstname} {clinic.doctor.profile?.lastname}</td>
												
														
														<td class="text-end">
															<div class="table-action">
																<Space>
															<Link to={"/"+i18n.language+"/clinic/"+clinic.id}  class="btn btn-sm bg-info-light">
																 {t('View')} 
																</Link>
																{/* <a href="#"  class="btn btn-sm bg-danger-light">
																 {t('Cancel')}
																</a>	 */}
																	<a onClick={()=>{deleteClinic(clinic.id)}}  class="btn btn-sm bg-danger-light">
																 {t('Delete')} 
																</a>
																															</Space>
															</div>
														</td> 													  
													</tr>
							
			
	</>: <></>
				
	   )
	}
	)
	) : (
	  <>No Doctors</>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	
	</div>
	</>):(<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}	
	</>
  )
}
