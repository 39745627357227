import Spin from 'antd/es/spin';
import React, { useState ,useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';


export const DashboradNavBar = ({info  ,setToken , profilePicture}) => {
    
    const [profilePic, setProfilePic] = useState(profilePicture);
    const {t} =useTranslation('home');
    if(info.success != true){
        return (<></>);
            }
if(info.data.polyclinic === null  || info.data.polyclinic === undefined){
return <></>;
}

if(info.data.polyclinic.id === null){
    return <></>;
    }


    
const handelLogout = ()=>{
	setToken(null);
	window.location = '/ar/login';
}
  return (
    <div class="profile-sidebar">
				<div class="widget-profile pro-widget-content">
					<div class="profile-info-widget">
						<a href="#" class="booking-doc-img">
                        <img  class="avatar-img rounded-circle" src={(profilePic != null ? (profilePic) : ('/assets/img/patients/patient.jpg'))}  alt={''} />
						</a>
						<div class="profile-det-info">
							<h3>{info.data.polyclinic.name}</h3>
							
							<div class="patient-details">
								<h5 class="mb-0">{info.data.polyclinic.bio}</h5>
							</div>
						</div>
					</div>
				</div>
    <div class="dashboard-widget">
    <nav class="dashboard-menu">
        <ul>
            <li >
                <Link to={"/"+i18n.language}>
                    <i class="fas fa-columns"></i>
                    <span>{t('Dashboard')}</span>
                </Link>
            </li>
            <li>
            <Link to={"/"+i18n.language+"/appointments"}>
                    <i class="fas fa-calendar-check"></i>
                    <span>{t('Appointments')}</span>
                </Link>
            </li>
            <li>
            <Link to={"/"+i18n.language+"/clinics"}>
                    <i class="fas fa-user-injured"></i>
                    {/* <span>{t('Clinics')}</span> */}
                    <span>اطباء</span>
                </Link>
            </li>
            <li>
            <Link to={"/"+i18n.language+"/createClinic"}>
                    <i class="fas fa-user-injured"></i> 
                    {/* <span>{t('Add Clinic')}</span> */}
                    <span>اضافة طبيب</span>
                </Link>
            </li>
            {/* <li>
            <Link to={"/"+i18n.language+"/patients"}>
                    <i class="fas fa-user-injured"></i>
                    <span>{t('Patients')}</span>
                </Link>
            </li> */}
            {/* <li>
                <a href="schedule-timings.html">
                    <i class="fas fa-hourglass-start"></i>
                    <span>{t('Schedule Timings')}</span>
                </a>
            </li>
            <li>
                <a href="available-timings.html">
                    <i class="fas fa-clock"></i>
                    <span>{t('Available Timings')}</span>
                </a>
            </li>
            */}
            {/* <li>
                <a href="doctor-profile-settings.html">
                    <i class="fas fa-user-cog"></i>
                    <span>{t('Profile Settings')}</span>
                </a>
            </li> */}
            <li>
                     <Link to={"/"+i18n.language+"/update-profile"}>
                    <i class="fas fa-user-injured"></i>
                    <span>{t('Update Profile')}</span>
                </Link>
               
            </li>
            <li>

                <a  href="#" onClick={handelLogout}>
                    <i class="fas fa-sign-out-alt"></i>
                    <span>{t('Logout')}</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
</div>
			
         
  )
}
