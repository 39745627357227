import { Divider, message } from 'antd';
import Skeleton  from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import { Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
export const ClinicPage = ({token }) => {
        
	const [load,setLoading] = useState(false);
	const {t} = useTranslation('home');
	const [displayData,setDisplayData] = useState([]);

	
	
	const [clinic,setClinicData] = useState();
	const [working_days,setWorkingDays] = useState();
	const [clinicName,setClinicName] = useState('');
	const [clinicFee,setClinicFee] = useState('');
	const [daysInAdvance,setDaysInAdvance] = useState('');
	const [consultationDuration,setConsultationDuration] = useState('');
	const [clinicPhone,setClinicPhone] = useState('');

	const [clinicService,setClinicService] = useState(null);


	const [timeslot, setTimeSlot] = useState(null);

	const [id , setId] = useState(null);
    const [clinicId , setClinicId] = useState(null);
    const [doctorId , setDoctorId] = useState(null);


	const onTimeSlotChange = (e, timeslot) => {

		e.stopPropagation();
		e.preventDefault();
		if (document.getElementsByClassName("timing selected").length > 0) {
			document
			  .getElementsByClassName("timing selected")[0]
			  .classList.remove("selected");
	
		  }
		e.target.classList.add("selected");
		setTimeSlot(timeslot);
	   
	
	   
	  };
    const params = useParams();
 
 const getAppointment = async ()=>{
    try {

		let response = await  fetch(CONSTANTS.DEFAULT_URL+'polyclinc/getTelehealth/'+params.id, {
		  method: 'GET',
		  headers: {
			'Content-Type': 'application/json' ,
			'Authorization': 'Bearer '+token

		  },
		})
		let json = await response.json();
		console.log(json);
		if(response.status !== 200){
			//   window.location = "/"+i18n.language+""
		}
	
			return { success: true, data: json };
	
		
	
	} catch (error) {
		return { success: false };
	  }

 }	
let workingDays = [
	{
		"day":"sunday",


		
			"ranges":[]
	},
	{
		"day":"monday",
		"ranges":[]

	},
	{
		"day":"tuesday",

		"ranges":[]

	},
	{
		"day":"wednesday",

		"ranges":[]

	},
	{
		"day":"thursday",

		"ranges":[]
	
	},
	{
		"day":"friday",

		"ranges":[]

	
	},
	{
		"day":"saturday",

		"ranges":[]

	
	}
];
const delete_range=(key , day)=>{
	let wW =   working_days.find(o => o.day === day);

	wW.ranges.splice(key, 1); // 2nd parameter means remove one item only

	setWorkingDays([...working_days]);

}
const isValidDate = function(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}
const updateClinic =async ()=>{

let realWorkingDays = [];

console.log(working_days);

working_days.forEach((element)=>{

	if(element.ranges.length>0){
		var r = [];
		var ran ={};
		element.ranges.forEach((element2)=>{
			if(isValidDate(element2.start)){
				var start = new Date(element2.start);


				start.setHours(start.getHours() - 3);
				var end = new Date(element2.end);
				end.setHours(end.getHours() - 3);

				var startMinutes =start.getMinutes();
				var endMinutes =end.getMinutes();
				var startHour =start.getHours();
				var endHour =end.getHours();

				startHour =String(startHour).padStart(2, '0');
				endMinutes =String(endMinutes).padStart(2, '0');
				endHour =String(endHour).padStart(2, '0');
				startMinutes =String(startMinutes).padStart(2, '0');

				// if(""+startMinutes.length === 1 ){
                // startMinutes = "0"+startMinutes;
				// }
				// if(""+endMinutes.toString.length === 1 ){
				// 	endMinutes = "0"+endMinutes;
				// 	}
				// 	if(""+startHour.toString.length === 1 ){
				// 		startHour = "0"+startHour;
				// 		}
				// 		if(""+endHour.toString.length === 1 ){
				// 			endHour = "0"+endHour;
				// 			}
				ran.start = startHour + ":" + startMinutes+":00" ;
				ran.end = endHour + ":" + endMinutes+":00" ;;
				ran.capacity = 10;

			}else{
				var sl = 		element2.start.split(":");
				var	s = parseInt(sl[0])-3;
					s = String(s).padStart(2, '0')+":"+sl[1]+":"+sl[2];
					var el = 		element2.end.split(":");

					var	ee = parseInt(el[0])-3;
						ee = String(ee).padStart(2, '0')+":"+el[1]+":"+el[2];
				ran.start = s;
				ran.end = ee;
				ran.capacity = 10;
			}
	

			r.push(ran);
		   

		})
		element.ranges = r;
		realWorkingDays.push(element);

	
}
})
console.log(realWorkingDays);


	await  axios.put(CONSTANTS.DEFAULT_URL+'polyclinic/updateClinic/'+id,{
		
			"data": {
				"serviceType": "telehealth",
				// "clinic_service": {
				// 	"clinic": {
				// 		name:clinicName,
				// 		phoneNumber:clinicPhone,

				// 	}
				// },
				"isRange": false,
				"fee": clinicFee,
				"consultationDuration": consultationDuration,
				"daysInAdvance": daysInAdvance,
				"working_days": realWorkingDays,
				"doctor": doctorId

			}
		
	}
	, {
	
	  headers: {
		'accept': 'application/json',

		'Content-Type': 'application/json',

		'Authorization': 'Bearer '+token

	  },
	}
	 ).then((response)=>{
		message.success("تم تحديث البيانات");

	 }).catch((error)=>{

	 });


}
const update_range =(key,day ,time  , type)=>{



	const timeslot = new Date(time);



let wW =   working_days.find(o => o.day === day);
if(type === "start"){
	wW.ranges[key] = {start: timeslot , end:wW.ranges[key].end,capacity:10};

}else if(type === "end"){
	wW.ranges[key] = {start:wW.ranges[key].start , end: timeslot,capacity:10};

}
 
 console.log(wW);

setWorkingDays([...working_days]);

}
const addTimeSlot = (day)=>{
	setLoading(false);
    let wW =   working_days.find(o => o.day ===day);
		 wW.ranges.push(
			{ "start": "11:11:00",
		"end": "12:12:00",
		"capacity": 10}
		 ); 
		 setWorkingDays([...working_days]);
		 setLoading(true);

}

 useEffect(() => {



getAppointment().then((e)=>{
	setClinicName(e.data.data.doctor.profile.firstname);
	setClinicFee(e.data.data.fee);
	// setClinicId(e.data.data.clinic_service.clinic.id);
	setDoctorId(e.data.data.doctor.id);
	setConsultationDuration(e.data.data.consultationDuration);
	setDaysInAdvance(e.data.data.daysInAdvance);

	setId(e.data.data.id);
  setClinicData(e.data.data);
  e.data.data.working_days.forEach((w)=>{
	
         let wW =   workingDays.find(o => o.day === w.day);
		 if(wW){
			wW.day = w.day;
			let ranges = [];
			w.time_ranges.forEach((e)=>{
		var sl = 		e.start.split(":");
	var	s = parseInt(sl[0])+3;
		s = String(s).padStart(2, '0')+":"+sl[1]+":"+sl[2];

		var el = 		e.end.split(":");
		var	ee = parseInt(el[0])+3;
			ee = String(ee).padStart(2, '0')+":"+el[1]+":"+el[2];
ranges.push({
	"start":s,
	"end": ee,
	"capacity": e.capacity
});
			})
			wW.ranges = ranges;
   
		 }


  })
  setWorkingDays(workingDays);


  setLoading(true)
})




	
 }, [])
 const apppendToTimeSlot = ()=>{

 }
 const convertToEgyTime = (datatime) => {
	const timeSlotDatetime = new Date(datatime).getTime();
	const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
	newTimeSlot.setHours(newTimeSlot.getHours() - 2);

	return newTimeSlot.toLocaleTimeString();
  };
  const ConvertToArabicNumbers = (num) => {
	if(i18n.language === 'ar'){
		const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
		return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]}).replace("PM","مساءا").replace("AM","صباحا");
	   
	}
	return num;
}
const doctor_name = (data)=>{
	let doctor_name_ar = '';
       if(data.doctor.profile){
		if(i18n.language === "ar"){
			return data.doctor.profile.firstname_ar === "" ? data.doctor.profile.firstname_ar+' '+data.doctor.profile.lastname_ar : data.doctor.profile.firstname+' '+data.doctor.profile.lastname;

		}
		return data.doctor.profile.firstname+' '+data.doctor.profile.lastname

	   }
	   return ''
}
const doctor_address = (data)=>{
// 	if(data.clinic_service.clinic.address){
// 		return data.clinic_service.clinic.address.tag

	

//    }
   return ''
}

  return (
<>{load ? (<>
	<div class="col-md-7 col-lg-8 col-xl-9">
		
		<div class="row">
			<div class="col-lg-12">
				<div class="card dash-cards">
		
					<div class="card-body">
						<div class="table-responsive">
						<table class="table table-hover table-center mb-0" > 
												<thead>
													<tr>
														{/* <th>{t('Clinic')}</th> */}
														<th>{t('Doctor')}</th>
														<th>{t('Fees')}</th>
														{/* <th>{t('Clinic Number')}</th> */}
                                                            <label>وقت الاستشارة لكل مريض</label>
														<th>{t('Number of days available for pre-booking')}</th>

														{/* <th>{t('Address')}</th> */}

														<th></th>
													</tr>
												</thead>
												<tbody>
			<tr>
				{/* <td><input className='form-control' disabled value={clinicName} onChange={(e)=>{setClinicName(e.target.value)}} /></td> */}
                     {/* <td>{clinicName}</td> */}
				<td>{doctor_name(clinic)}</td>
				<td><input className='form-control' value={clinicFee} onChange={(e)=>{setClinicFee(e.target.value)}} /></td>
				{/* <td><input className='form-control' value={clinicPhone} onChange={(e)=>{setClinicPhone(e.target.value)}} /></td> */}
				<td><input className='form-control' value={consultationDuration} onChange={(e)=>{setConsultationDuration(e.target.value)}} /></td>
				<td><input className='form-control' value={daysInAdvance} onChange={(e)=>{setDaysInAdvance(e.target.value)}} /></td>

				{/* <td>{doctor_address(clinic)}</td> */}

				<td></td>

			</tr>
									
					</tbody>
											</table>
																<Divider />
																
					<div class="card">
						<div class="card-body pt-0">
						<h3 className='text-success '><b>{t('Clinic Times')}</b> </h3>
						   <>
						   {working_days.length > 0 ?
																(working_days.map((day , key) => {
																	return (
																		<>
																		<Row>
																			<Col md={3}>
																			<h3 className='text-info '><b>{t(day.day)}</b> </h3>

																			</Col>
																			<Col xs={2}>
																			<button onClick={()=>addTimeSlot(day.day)} className='btn btn-success' style={{color:'black'}} >{t('Add')}</button>

																			</Col>
																		</Row>
																
																		<table className='table'>
																			<tr className='warning'>
																				<th>{t('Start')}</th>
																				<th>{t('End')}</th>
																				<th></th>

																			</tr>
																			<tbody className={day.day}>
																		{day.ranges.map((timeslot , key) => {
																	return (<>
																				<tr>
																					<td><TimePicker  onChange	={(e)=>{update_range(key,day.day , e , "start")}} defaultValue={(dayjs(timeslot.start  , 'HH:mm:ss'))} /></td>
																					<td><TimePicker  onChange	={(e)=>{update_range(key,day.day , e,"end")}} defaultValue={(dayjs(timeslot.end , 'HH:mm:ss'))}  /></td>
<td></td>																					<td><Button className='text-danger' onClick={()=>{delete_range(key , day.day)}}>{t('Delete')}</Button></td>

																				</tr>
																				</>
																	)})}
																	</tbody>
																		</table>
					

																</>
																	)
																})
																
																) : (<>{t('Loading')}</>)
															}
						
						<br/>

																<button onClick={()=>updateClinic()} className='btn btn-success' style={{color:'black'}} >{t('Update')}</button>
																<br/>
						   </>
				      
						
						</div>
					</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	
	</div>
	</>):(<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}	
	</>
  )
}

  