import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate, useParams } from 'react-router-dom'
import i18n from '../../i18n';
import * as CONSTANTS from '../../CONSTANTS';
import Spin from 'antd/es/spin';
import { message } from 'antd';

// async function registerPatient(credentials) {

//     try {

//     let response = await  fetch(CONSTANTS.DEFAULT_URL+'auth/local/register', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(credentials)
//     })
//     let json = await response.json();

//         return { success: true, data: json };

    

// } catch (error) {
//     return { success: false };
//   }
// }
export default function  Register ({setToken , token})  {
const navigate = useNavigate();

const {t} = useTranslation("home");
    const [load , setLoading] = useState(false);
  
    const [messageBody , setMessage] = useState('');
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState();
    const [firstname, setfirstName] = useState();
    const [lastname, setlastName] = useState();
    const [email, setEmail] = useState();
const params = useParams();
//     const handleSubmit = async e => {
//         e.preventDefault();
//         setMessage('');
//         if(firstname.length < 3){
//           message.error('الاسم الاول يجد ان يكون اكثر من 3 احرف');
//           return false;
//      }
//      if(lastname.length < 3){
//        message.error(t('الأسم الثاني يجب ان  يكون اكثر من 3 احرف'));
//        return false;
//    }

//         setLoading(true);
//         const token = await registerPatient({
//             username:'+'+username,
//           password:password,
//           lastname:lastname,
//           firstname:firstname,
//           // email:email,
//           type:"polyclinic",
          
//           web:'true',
//           applicationLanguage:i18n.language,
//         });
       

// if(token.data.error){
//     if(token.data.error.name === 'ApplicationError'){
//         setLoading(false);
//     if(token.data.error.message === 'Invalid Mobile Number Format'){
//       setMessage('رقم الهاتف غير صحيح');

//     }else{
//       setMessage(token.data.error.message);

//     }
//        return false;
//     }
// }
// // navigate('/'+i18n.language+'/login/'+params.type);
//         if(token.data.jwt != null){
//             setLoading(false);

//             setToken(token.data.jwt);
//             navigate('/'+i18n.language+'/otp');


            
//         }
//       }
   
		useEffect(() => {

			if(token != null ){
				setLoading(true);
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+token
				  }
                axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
					headers: headers
				  })
        .then(function (response) {
          // handle success
               if(response.status === 200){
                navigate('/');

               }
		  setLoading(false);

        })
        .catch(function (error) {
          // handle error
		  console.log(error.response.status);
        //  setToken(null);
		if(error.response.status === 403){
			navigate('/login');


		}
          console.log(error);
        })
        .finally(function () {
			setLoading(false);
        });
	}else{
		setLoading(false);

	}

	}, [params]);
  return (
   <>
   		
			<div class="content">
				<div class="container">
					
					<div class="row">
						<div class="col-md-8 offset-md-2">
								
							<div class="account-content">
								<div class="row align-items-center justify-content-center">
									<div class="col-md-7 col-lg-6 login-left">
										<img src="/assets/img/Logo&Slogan (Colored).png" class="img-fluid" alt=" Register" />	
									</div>
									<div class="col-md-12 col-lg-6 login-right">
										<div class="login-header">
											<h3>{t('Register')}  </h3>
                                            <p className='text-danger'>{messageBody}</p>

										</div>
										
                                      <h1>Please contact support call <a href='tel:+201288883699'>01288883699</a></h1>
										
									</div>
								</div>
							</div>
								
						</div>
					</div>

				</div>

			</div>		
			</>
  )
}
