import AgoraRTC from 'agora-rtc-react';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';

export const rtc = {
    // For the local client
    client: null,
    // For the local audio and video tracks
    localAudioTrack: null,
    localVideoTrack: null,
  };
 
export default function Call({token}) {
  const params = useParams();
 
   const options = {
    // Pass your app ID here
    appId: "c0d00230fa7c46928e28346015525ff8",
    // Pass a token if your project enables the App Certificate
    token:params.token,
  };
    async function handleSubmit() {
      
        try {
          setJoined(true);
    
          rtc.client = AgoraRTC.createClient();
      await rtc.client.join(
            options.appId,
            params.channel,
            options.token,
            params.userId
          );
    
          // Create an audio track from the audio captured by a microphone
          rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
          // Create a video track from the video captured by a camera
          rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    
          rtc.localVideoTrack.play("local-stream");
    
          rtc.client.on("user-published", async (user, mediaType) => { 
            // Subscribe to a remote user
            await rtc.client.subscribe(user);
            console.log("subscribe success");
            // console.log(user);
    
            if (mediaType === "video" || mediaType === "all") {
              // Get `RemoteVideoTrack` in the `user` object.
              const remoteVideoTrack = user.videoTrack;
              console.log(remoteVideoTrack);
    
              // Dynamically create a container in the form of a DIV element for playing the remote video track.
              const PlayerContainer = React.createElement("div", {
                id: user.uid,
                className: "stream",
              });
              ReactDOM.render(
                PlayerContainer,
                document.getElementById("remote-stream")
              );
    
              user.videoTrack.play(`${user.uid}`);
            }
    
            if (mediaType === "audio" || mediaType === "all") {
              // Get `RemoteAudioTrack` in the `user` object.
              const remoteAudioTrack = user.audioTrack;
              // Play the audio track. Do not need to pass any DOM element
              remoteAudioTrack.play();
            }
          });
    
          rtc.client.on("user-unpublished", (user) => {
            // Get the dynamically created DIV container
            const playerContainer = document.getElementById(user.uid);
            console.log(playerContainer);
            // Destroy the container
            playerContainer.remove();
          });
    
          // Publish the local audio and video tracks to the channel
          await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    
          console.log("publish success!");
        } catch (error) {
          console.error(error);
        }
      }
    
      async function handleLeave() {
        try {
          const localContainer = document.getElementById("local-stream");
    
          rtc.localAudioTrack.close();
          rtc.localVideoTrack.close();
    
          setJoined(false);
          localContainer.textContent = "";
    
          // Traverse all remote users
          rtc.client.remoteUsers.forEach((user) => {
            // Destroy the dynamically created DIV container
            const playerContainer = document.getElementById(user.uid);
            playerContainer && playerContainer.remove();
          });
    
          // Leave the channel
          await rtc.client.leave();
        } catch (err) {
          console.error(err);
        }
      }
      const [joined, setJoined] = useState(false);
      const channelRef = useRef(params.channel);
      const remoteRef = useRef("");
      const leaveRef = useRef("");
    useEffect(() => {
    //   // setJoined(true);
      handleSubmit();
      
    }, [])
    
      return (
      <>
        <div class="col-md-7 col-lg-8 col-xl-9">
		
		<div class="row">
			<div class="col-lg-12"> 
				<div class="card dash-cards">
		
					<div class="card-body">
          <div className="container">
            {/* <input
              type="text"
              ref={channelRef}
              id="channel"
              placeholder="Enter Channel name"
            />
            <input
              type="submit"
              value="Join"
              onClick={handleSubmit}
              disabled={joined ? true : false}
            /> */}
            <input
              type="button"
              ref={leaveRef}
              value="انهاء المكالمة"
              onClick={handleLeave}
              disabled={joined ? false : true}
            />
          </div>
          {joined ? (
            <>
              <div id="local-stream" className="stream local-stream"></div>

              <div
                id="remote-stream"
                ref={remoteRef}
                className="stream remote-stream"
              ></div>
            </>
          ) : null}
        
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
      );
}
