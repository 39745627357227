import { Select, Skeleton,TimePicker } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18n from '../../i18n'
import * as CONSTANTS from '../../CONSTANTS'
import axios from 'axios'
import { Button,Modal } from 'antd';
import { useTranslation } from 'react-i18next'
import { check_auth } from '../../helper'
import message from 'antd/es/message';
import Upload from 'antd/es/upload';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export const UpdateProfile = ({token}) => {
    const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const [progress, setProgress] = useState(0);
	const [uploadId, setUploadId] = useState(false);
	const [loading, setLoadings] = useState(false);
	const [imageUrl, setImageUrl] = useState();

	const uploadImage = async options => {
		const { onSuccess, onError, file, onProgress } = options;
		console.log(file);

		const fmData = new FormData();
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
				"content-type": "multipart/form-data"
			},
			onUploadProgress: event => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
				onProgress({ percent: (event.loaded / event.total) * 100 });
			}
		};
		fmData.append("files", file);
		try {
			const res = await axios.post(
				CONSTANTS.DEFAULT_URL + "upload",
				fmData,
				config
			);

			onSuccess("Ok");
			console.log("server res: ", res.data[0].id);
			//profilePicture
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			}
			const userData = await check_auth(token);
			axios.put(CONSTANTS.DEFAULT_URL + 'users/' + userData.data.id, {
				"profilePicture": res.data[0].id

			}, { headers: headers })
				.then(function (response) { });
			setUploadId(res.data[0].id);

		} catch (err) {
			console.log("Eroor: ", err);
			const error = new Error("Some error");
			onError({ err });
		}
	};
	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoadings(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (url) => {
				setLoadings(false);
				// setProfilePic(url);
			});
		}
	};



    const chagnePassword = async () => {
		const userData = await check_auth(token);
		let data = {    "applicationLanguage":"ar", "locale":"ar",
	};



		if(newPassword !== null && newPassword !== ""){
      if(oldPassword.trim().length == 0){
message.error("كلمة المرور القديمه مطلوبه");
return false;
      }
            data.password = newPassword;
			data.oldPassword = oldPassword;

		}
    if(newPassword !== confirmPassword){
      message.error("تأكيد كلمة المرور غير صحيح");
   return false;

}
		if(email !== null && email !== ""){
            data.email = email;

		}
    if(address !== null && address !== ""){
      data.street = address;

}
if(clinicarea !== null && clinicarea !== ""){
  data.city = clinicarea;

}else{
  data.city = clinicAreaVal;
}

if(zipCode !== null && zipCode !== ""){
  data.zipCode = zipCode;

}

		let response = axios.put(CONSTANTS.DEFAULT_URL + 'users/' + userData.data.id, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			}
		}).then((response) => {
			console.log(response);
message.success(t('Updated Successfully'));
setNewPassword('');
setOldPassword('');
setConfirmPassword('');
window.location.reload();
		}).catch((error) => {
			console.log(error.response.data.error);
			// error.response.data.error.details.errors.forEach(element => {
            message.error(error.response.data.error.message);

			// });


		});






	}


    const onChange = (time) => {
        setValue(time);
      };
    const [value, setValue] = useState(null);

    const {t} = useTranslation('home');


  
    
 
//http://3.70.182.78:1337/api/clinics?filters[$and][0][doctors][licenseNumber][$eq]=16881
const [load , setLoading] = useState(true);

const [userData , setData] = useState();
const [title , setTitle] = useState(null);
const [messageApi, contextHolder] = message.useMessage();
const [date , setDate] = useState(null);
const [patient , setPatient] = useState(null);
const [oldPassword , setOldPassword] = useState(null);
const [newPassword , setNewPassword] = useState(null);
const [confirmPassword , setConfirmPassword] = useState(null);
const [profilePic, setProfilePic] = useState(null);
const [email, setEmail] = useState(null);
const [cliniccity , setCliniccity] = useState(null);

const [address , setAddress] = useState(null);
const [zipCode , setzipCode] = useState(null);
const [clinicarea , setclinicarea] = useState(null);

const { Option } = Select;
const [clinicLoad , setClinicsLoad] = useState(null);
const [cities , setCities] = useState([]);
const [areas , setAreas] = useState([]);
const navigate = useNavigate();
const [cliniccityText , setcliniccityText] = useState('');

const [clinicAreaVal , setclinicAreaVal] = useState('');


useEffect(() => {
 
setLoading(false);
    if(token != null ){
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
        axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
            headers: headers
          })
.then(function (response) {
 
  setLoading(true);
       if(response.status === 200){
        setData(response.data);
		setEmail(response.data.email);
    if(response.data.polyclinic.address){
      setAddress(response.data.polyclinic.address.street);
      // setclinicarea(response.data.polyclinic.address.cities[0]?.id);
      setcliniccityText(response.data.polyclinic.address?.cities[0]?.name);
      setclinicAreaVal(response.data.polyclinic.address?.cities[0]?.id);
  
  
      
      setzipCode(response.data.polyclinic.address?.zipCode);
    }
 


        setLoading(false);

       }else{
        navigate('/login');
       }
    

})
.catch(function (error) {
            navigate('/login');


setLoading(true);

if(error.response.status === 403){
            navigate('/login');



}
  console.log(error);
})
// .finally(function () {
//     setLoading(false);
// });
}else{
        navigate('/login');


setLoading(true);

}
const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+token
  }


axios.get(CONSTANTS.DEFAULT_URL+'states?pagination[page]=1&pagination[pageSize]=1000' , {
    headers: headers
  })
.then(function (response) {

    setCities(response.data.data);
})
.catch(function (error) {
// handle error
console.log(error);

});
}, []);
const setCliniccityF =async (e)=>{
    setCliniccity(e);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
      }
    axios.get(CONSTANTS.DEFAULT_URL+'cities?pagination[page]=1&pagination[pageSize]=1000&filters[$and][0][state][id][$eq]='+e , {
        headers: headers
      })
    .then(function (response) {
    
        setAreas(response.data.data);
    })
    .catch(function (error) {
    // handle error
    console.log(error);
    
    console.log(error);
    });
}
const uploadButton = (
    <div>
        {loading ? <LoadingOutlined /> : <div class="upload-img">
            <div class="change-photo-btn">
                <span><i class="fa fa-upload"></i>{t('Change Profile Picture')}</span>
                <input type="file" class="upload" />
            </div>
        </div>}
        {/* <div class="upload-img">
                                                        <div class="change-photo-btn">
                                                            <span><i class="fa fa-upload"></i>{t('Change Profile Picture')}</span>
                                                            <input type="file" class="upload" />
                                                        </div>
                                                    </div> */}
    </div>
);

  return (
    <Suspense>
        {load === false ? (<>


            <div class="col-md-7 col-lg-8 col-xl-9">
                            <div class="card">
                                <div class="card-body">
                                <form>
                                                <div class="row form-row">
                                                <div class="col-12 col-md-12">
														<div class="form-group">
															<div class="change-avatar">
																<Upload
																	accept="image/*"
																	customRequest={uploadImage}
																	onChange={handleChange}
																	listType="picture-card"
																	className="image-upload-grid"
																>
																	{imageUrl ? (
																		<></>
																	) : (
																		uploadButton
																	)}
																</Upload>
																{/* <div class="profile-img">
																	<img src={(profilePic != null ? (profilePic) : ('/assets/img/patients/patient.jpg'))} alt={''} />
																</div> */}

															</div>
														</div>
													</div>
													
											  <div class="col-12 col-md-6">
                                                        <div class="form-group">
														<label class="focus-label">{t('Email')}</label>
														<input type="email" class="form-control floating" onChange={(e) => setEmail(e.target.value)} value={email} />
                                                        </div>
                                                    </div>
													<div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('ِAddress')} </label>
                                                            <input type="text" onChange={(e)=>{setAddress(e.target.value)}} value={address} class="form-control"  />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('ZipCode')} </label>
                                                            <input type="text" onChange={(e)=>{setzipCode(e.target.value)}} value={zipCode}   class="form-control"  />
                                                        </div>
                                                    </div>
                                              <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('State')} </label>
                                               
                    <Select
                    size='large'

                      showSearch
                      placeholder={t('Select Area')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                        option.props.label.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }
                      value={cliniccity}
                      onChange={(e) =>setCliniccityF(e)}

                      className="col-md-12 "
                    >
                      {cities.map((c, i) => {
                        return (
                          <Option value={c.id} key={c.attributes.name} label={c.attributes.name}>

                            <>{c.attributes.name}</>
                          </Option>
                        );
                      })}                  </Select></div>
                                              
                                                 

</div>
<div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Area')} ({cliniccityText}) </label>
                                               
                    <Select
                    size='large'
                    aria-autocomplete='false'
 
                      showSearch
                      placeholder={t('Select City')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                        option.props.label.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0                      }
                      onChange={(e) =>{ setclinicarea(e)}}
                      className="col-md-12 "
                    >
                      {areas.map((a, i) => {
                        return (
                          <Option value={a.id} key={a.attributes.name} label={a.attributes.name}>
                            <>{a.attributes.name}</>
                          </Option>
                        );
                      })}                  </Select></div>
                                              
                                                 

</div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Old Password')}</label>
                                                            <input type="password" class="form-control" onChange={(e)=>{setOldPassword(e.target.value)}} value={oldPassword} />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('New Password')}</label>
                                                            <input type="password" class="form-control" onChange={(e)=>{setNewPassword(e.target.value)}} value={newPassword} />
                                                        </div>
                                                    </div>


                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Confirm Password')}</label>
                                                            <input type="password" class="form-control" onChange={(e)=>{setConfirmPassword(e.target.value)}} value={confirmPassword} />
                                                        </div>
                                                    </div>
                                                 
                                           
       

                   </div>
{/* a                                                                                           sync function registerAddress(token ,note , patient , street , lat ,lng , defaultAddress = false,tag  ,  city  ,zipCode ) { */}


                                                <div class="submit-section">
                                                    <button type="button" onClick={()=>{chagnePassword()}} class="btn btn-primary submit-btn">{t('Save Changes')}</button>
                                                </div>
                                            </form>
                                </div>
                            </div>
                        </div>
                 </>) : (<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}

    </Suspense>

  )
}